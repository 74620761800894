import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import SEO from "../components/SEO"

import logo from "../assets/404.svg"

const Error = () => {
  return (
    <Layout>
      <SEO title="404" description="Page not Found" />

      <div className="wrapper">
        <div className="not-found">
          <img src={logo} alt="" className="not-found-img" />

          <div className="subheading">
            The Page you're looking for is not valid!
          </div>

          {/* <div className="btn mt-2">

          </div> */}
          <Link
            className="btn"
            style={{ marginTop: "5vh", marginBottom: "10vh" }}
            to="/"
          >
            Go to Home
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default Error
